.navbar {
  height: 60px;
  width: 100%;
}

.download-button {
  position: absolute;
  right: 30px;
  top: 20px;
  font-weight: 600;
  color: var(--text-color);
}

.logo {
  font-weight: var(--link-font-weight);
  color: var(--text-color);
}
