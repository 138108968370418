body {
  margin: 0;
  padding: 0;
  font-family: Satoshi, sans-serif;
  overflow-x: hidden;
  font-size: 18px;
  font-weight: 500;

  --background-color: linear-gradient(
    90deg,
    rgba(201, 228, 220, 1) 0%,
    rgba(205, 219, 213, 1) 46%,
    rgba(165, 255, 235, 1) 100%
  );
  --name-text-color: #4e4b66;
  --text-color: #14142b;
  --link-color: #007bff;
  --link-hover-color: #0056b3;
  --header-font-weight: 700;
  --default-font-weight: 400;
  --link-font-weight: 600;
  --title-font-size: 1.5rem;
  --default-font-size: 1rem;
  --header-font-size: 42px;
  --name-font-size: 24px;
  --about-font-size: 68px;
}

@media (max-width: 600px) {
  body {
    --title-font-size: 0.75rem;
    --default-font-size: 0.5rem;
    --header-font-size: 21px;
    --name-font-size: 12px;
    --about-font-size: 34px;
  }
}
