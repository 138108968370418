.about-container {
  /* width: 100vw; */
  background: var(--background-color);
  overflow: hidden;
}

.about-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 150px;
}

.header {
  color: var(--text-color);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  line-height: 1.38em;
}

.about-left {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  border-radius: 50px;
  overflow: hidden;
}

.about-right {
  width: 50%;
  margin-left: 25px;
  font-weight: var(--default-font-weight);
}

.skill-left {
  width: 50%;
  display: inline-grid;
  justify-content: end;
  font-weight: var(--default-font-weight);
}

.skill-right {
  width: 50%;
  margin-left: 25px;
  border-radius: 50px;
  overflow: hidden;
}

.profiles {
  color: var(--link-color);
  margin-right: 20px;
  font-weight: var(--link-font-weight);
}

.project-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 150px;
}

.carousel-nav {
  width: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  line-height: 1.38em;
}

.projects {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 50%;
  height: 350px;
}

.project-card {
  margin-bottom: 20px;
  width: 100%;
  height: 300px;
}

.project-title {
  font-size: var(--title-font-size);
  margin-bottom: 10px;
  font-weight: var(--default-font-weight);
}

.project-description {
  font-size: var(--default-font-size);
  margin-top: 10px;
  color: var(--text-color);
  font-weight: var(--default-font-weight);
}

.demo-link {
  display: block;
  font-size: var(--default-font-size);
  font-weight: var(--link-font-weight);
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.2s ease;
  text-align: center;
}

.demo-link:hover {
  color: var(--link-hover-color);
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .step {
    width: 100%;
    display: block;
    margin-top: 50px;
  }

  .about-left {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .about-right {
    width: 100%;
    margin-left: 25px;
    font-weight: var(--default-font-weight);
  }

  .skill-left {
    width: 100%;
    display: block;
    margin-left: 25px;
    font-weight: var(--default-font-weight);
    margin-bottom: 10px;
  }

  .skill-right {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    border-radius: 0;
  }
}
