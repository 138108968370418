.homepage {
  /* width: 100%; */
  height: 100vh;
  min-height: 100%;
  position: absolute;
  background: var(--background-color);
}

.name-text {
  font-size: var(--name-font-size);
  font-weight: var(--header-font-weight);
  color: var(--name-text-color);
  margin-bottom: 20px;
  line-height: 1.333em;
  font-family: Satoshi, sans-serif;
}

.about-text {
  font-size: var(--about-font-size);
  font-weight: var(--header-font-weight);
  color: var(--text-color);
  margin-top: 0;
  line-height: 1.3em;
  font-family: Satoshi, sans-serif;
}

.moving-clouds {
  width: 100%;
  height: 50%;
}

.profile-section {
  width: 100%;
  height: 17%;
  margin: 15% 10% 0% 10%;
  display: flex;
  flex-direction: row;
}

.intro {
  width: 40%;
}

.picture {
  width: 50%;
  display: flex;
  align-items: center;
  padding-top: 150px;
}

.copyright {
  width: 100%;
  height: 2%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  font-size: small;
}
